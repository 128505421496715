import React from 'react'
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Popup,
  Segment,
} from 'semantic-ui-react';
import LogoWhite from '../assets/images/logoWhite.png';


const Footer = () => {

    const [copied, setCopied] = React.useState(false);

    React.useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, [3500]);
        }
    }, [copied]);

    function fallbackCopyTextToClipboard(text: string) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
            setCopied(true);

        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }
      function copyTextToClipboard(text: string) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            setCopied(true);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }

    return (
<Segment inverted vertical style={{ padding: '5em 0em', marginTop: 20 }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={5}>
              {/* <Header inverted as='h4' content='About' /> */}
              <Image src={LogoWhite} size={'tiny'} />

              <List link inverted>
                <Popup 
                    content={
                        'Click to copy'
                    }
                    size={'mini'}
                    // inverted
                    position={'bottom center'}
                    trigger={
                        <List.Item as='a' onClick={() => copyTextToClipboard('info@froste.com.au')}>{copied ? 'Email copied to clipboard!' : 'Email: info@froste.com.au'}</List.Item>
                    } 
                />

                  <List.Item as='a'>
                    <a href={'https://www.instagram.com/froste.com.au/'} target={'_blank'}><Icon name={'instagram'} inverted color={'black'}  size={'large'} /></a>
                    <a href={'http://facebook.com/frostecomau'} target={'_blank'}><Icon name={'facebook'} inverted color={'black'}  size={'large'} /></a>
                  </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
                <List link inverted>
                    <List.Item as='a'><Link to={'/'}>Home</Link></List.Item>
                    <List.Item as='a'><Link to={'/products'}>Products</Link></List.Item>
                    <List.Item as='a'><Link to={'/checkout'}>Checkout</Link></List.Item>
                    </List>
      
            </Grid.Column>
            <Grid.Column width={2}>
                <List link inverted>
                    <List.Item as='a'><Link to={'/shipping'}>Shipping</Link></List.Item>
                    <List.Item as='a'><Link to={'/returns'}>Returns</Link></List.Item>
                    
                </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
    );
}

export default Footer;