/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import {
  Button,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react';

import Stock from './assets/images/BeachCover.jpg';
import StockVideo from './assets/images/BeachCover.mp4';
import MobileBg from './assets/images/MobileBg.png';
import Logo from './assets/images/logoWhite.png';
import { Link } from 'react-router-dom';
import {
    isMobile
  } from "react-device-detect";
  import { HomepageProductColumn } from './components/HomepageProductColumn';
  import { ProductsData as products } from './product-config';
import useWindowDimensions from './useWindowDimensions';

const IMAGE_HEIGHT = 924;
const IMAGE_WIDTH = 1640;
const IMAGE_RATIO = IMAGE_HEIGHT / IMAGE_WIDTH;

const HomepageLayout = () => {

    const [videoLoaded, setVideoLoaded] = React.useState(false);
    const [desktopBgHeight, setDesktopBgHeight] = React.useState(600);

    const { width } = useWindowDimensions();

    React.useEffect(() => {

      if (videoLoaded) return;

      const height = width * IMAGE_RATIO;
      setDesktopBgHeight(height);

    }, [videoLoaded, width]);


    return (
  <>
        {isMobile ? (
          <Segment
          inverted
          textAlign='center'
          style={{ 
              minHeight: 700, 
              padding: '1em 0em',
              display: 'inline-block',
              opacity: 1,
              backgroundImage: `url(${MobileBg})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
              height: '100%', 
          }}
          vertical
      >

      </Segment>
        ) : (
        <Segment
            inverted
            textAlign='center'
            style={{ 
                minHeight: videoLoaded ? 0 : desktopBgHeight, 
                padding: '0',
                display: 'inline-block',
                opacity: 1,
                backgroundImage: videoLoaded ? undefined : `url(${Stock})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: '100%',
                height: '100%', 
            }}
            vertical
        >
          <video autoPlay muted loop id="myVideo" style={{ width: '100%'}} onCanPlay={() => {
              setVideoLoaded(true)}}>
            <source src={StockVideo} type="video/mp4" / >
          </video>

        </Segment>
          )}

    <Segment style={{ padding: '2em 0em' }} vertical>
      <Grid container stackable columns={4} verticalAlign='middle'>
          <Grid.Row centered>
            <div style={{
              lineHeight: '1.5em',
              textAlign: 'center',
              fontSize: '18px',
              maxWidth: 500,
            }}>
          Join us in the fight against warm beer.

Like a keep-cup for your beer, <strong>FROSTE</strong> stubby holders cutting edge double-wall vacuum design ensures your drinks are ice cold until the last drop.

Fitting most standard bottles & cans, try one out today and taste the difference.

            </div>
          </Grid.Row>
          <Grid.Row centered>
            <Header as='h3' style={{ fontSize: '2em' }}>
              THE COLLECTION
            </Header>
          </Grid.Row>
        <Grid.Row verticalAlign={'top'}>
          {products.map(product =>
            product.colours.map(colour => (
              <HomepageProductColumn
                  images={colour.images}
                  header={colour.name}
                  productId={product.identifier}
                  colourId={colour.id}
              />

            ))
          )}
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column textAlign='center'>
            <Link to={'/products'}>
                <Button size='huge' color={'black'} style={{ padding: '10px 50px' }} >SHOP NOW</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
        {isMobile && (
        <Grid.Row centered>

          <Grid.Column>
            <Link to={'/product'}>
                <Image src={Logo} size={'small'}  centered/>
            </Link>
            <Header as='h3' style={{ fontSize: 25 }} textAlign={'center'}>
                COLD TILL THE LAST DROP
            </Header>
          </Grid.Column>
          </Grid.Row>
            )}
      </Grid>
    </Segment>

    
  </>
);
}

export default HomepageLayout