import { Config } from "./config";
import { Product } from "./models";

import seltzer_white from './assets/images/whiteSkinny.jpg';
import seltzer_white_info from './assets/images/WhiteSkinny_Info.png';
import seltzer_black from './assets/images/blackSkinny.jpg';
import seltzer_black_info from './assets/images/blackSkinny_info.webp';
import ws1 from './assets/images/white_skinny/258414100_388715309608336_600429232208192570_n~2.jpg';
import ws3 from './assets/images/white_skinny/IMG_20210309_194026.jpg';
import stubby_1 from './assets/images/375/PXL_20211125_223431171.PORTRAIT~2.jpg';
import stubby_2 from './assets/images/375/PXL_20211125_223149787~2.jpg';
import stubby_3 from './assets/images/375/PXL_20211125_223347966~2.jpg';
import black_375_info_1 from './assets/images/Black_Stubby_Info_Cans.png';
import black_375_info_2 from './assets/images/Black_Stubby_Info_Bottles.png';

import stubby_black from './assets/images/blackOfficial.jpg';
import White375 from './assets/images/whiteSilverStubby.jpg';
import White375_info_1 from './assets/images/White_Stubby_Info_Bottles.png';
import White375_info_2 from './assets/images/White_Stubby_Info.png';


export const productData = (
    identifier: string,
    name: string,
    description: React.ReactNode,
    shortName: string,
    whiteImages: string[],
    blackImages: string[],
    outOfStock?: boolean,
): Product => {

    const black = Config.products.find(configProduct => configProduct.identifier === identifier && configProduct.colour === 'midnight-black');
    const white = Config.products.find(configProduct => configProduct.identifier === identifier && configProduct.colour === 'arctic-white');

    const blackColour = black ? {
        id: 'midnight-black',
        stripeId: black!.productId,
        hash: '#000',
        name: `Midnight Black - ${shortName}`,
        images: blackImages,
        price: {
            stripeId: black!.priceId,
            priceInCents: black!.price,
        }
    } : undefined;

    const whiteColour = white ? {
        id: 'arctic-white',
        stripeId: white!.productId,
        hash: '#fff',
        name: `Arctic White - ${shortName}`,
        images: whiteImages,
        price: {
            stripeId: white!.priceId,
            priceInCents:  white!.price,
        }
    } : undefined;


    return {
        identifier,
        name,
        colours: [
            whiteColour,
            blackColour,
        ].filter(c => c !== undefined) as Product['colours'
        ],
        description,
        outOfStock: outOfStock ?? false,
    }
}

export const stubbyDescription = () => <p><strong>FROSTE</strong> stubby holders, designed using innovative double-walled vacuum insulation technology, provide the answer to keeping your drink cold on those warm days.
<br/><br/><br/>
Our stubby holders are designed to fit both standard cans (375ml) and bottles. The screw lid with rubber lip seals and holds your drink tightly in place.
<br/><br/><br/>
Made from high quality 18/8 food grade stainless steel, <strong>FROSTE</strong> offers unrivalled practacility and durability. </p>;

export const seltzerDescription = () => <div><p style={{ fontSize: 18 }}><strong>FROSTE</strong> stubby holders, designed using innovative double-walled vacuum insulation technology, provide the answer to keeping your drink cold on those warm days.
<br/><br/>
Our unique Seltzer holders are specially designed to fit the standard 330ml skinny cans. The screw lid with rubber lip seals and holds your drink tightly in place.
<br/><br/>
Made from high quality 18/8 food grade stainless steel, <strong>FROSTE</strong> offers unrivalled practacility and durability.</p></div>;

export const ProductsData: Product[] = 
    [
        productData(
            'stubby-holder',
            '375ml Stubby Holder',
            stubbyDescription(),
            '375ml',
            [White375, White375_info_1, White375_info_2, stubby_1, stubby_2, stubby_3],
            [stubby_black, black_375_info_1, black_375_info_2, stubby_1, stubby_2, stubby_3],
            true, // out of stock
        ),
        productData(
            'seltzer',
            '330ml Slim Can/Seltzer Holder',
            seltzerDescription(),
            '330ml',
            [seltzer_white, seltzer_white_info, ws1, ws3],
            [seltzer_black, seltzer_black_info, ws1, ws3],
        ),
    ];
