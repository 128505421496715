import React from 'react';
import { CartContext, FREE_SHIP } from './CartContext';
import { Image, Table, Grid, Header, Input, Breadcrumb } from 'semantic-ui-react'
import { QuantityPicker } from './components/Quantity';
import Checkout from './components/stripe/Checkout';
import * as EmailValidator from 'email-validator';

import Pay from './components/stripe/Pay';
import { formatCents } from './format';
import ResponseiveDiv from './components/ResponsiveDiv';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Button from './components/Button';

const CheckoutPage = () => {
    const [cart, , , setQuantity, clearCart] = React.useContext(CartContext)

    const [shipping, setShipping] = React.useState(895);
    const [showPay, setShowPay] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [validEmail, setValidEmail] = React.useState(false);

    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        setTotal(cart.reduce((total, item) => total + item.quantity * item.price.priceInCents, 0));

    }, [cart]);

    React.useEffect(() => {
        setValidEmail(EmailValidator.validate(email)); // true
    }, [email]);

    return total > 0 ? (
            <div
                style={{
                    width: '90%',
                    maxWidth: 700,
                    marginTop: 50,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <div style={{
                marginLeft: 40,
                marginBottom: isMobile ? 40 : 0
            }}>
                <Breadcrumb>
                    <Link to={'/'}>
                        <Breadcrumb.Section link>Home</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider  icon='right angle' />
                    <Link to={'/products'}>
                        <Breadcrumb.Section link>Products</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider  icon='right angle' />
                    <Breadcrumb.Section active>Cart</Breadcrumb.Section>
                </Breadcrumb>
            </div>
            
                <Header textAlign={'center'} as={'h1'}>
                    CART
                </Header>
                    <Table>
                        {cart.map(product => (
                        <Table.Row key={product.stripeId}>
                            <ResponsiveCell>
                                <Image src={product.image} size={'small'} centered={isMobile} />
                            </ResponsiveCell>

                            <ResponsiveCell>
                                <strong>{product.productName}</strong><br /><br />
                                {product.colourName}<br/><br/>
                                {formatCents( product.price.priceInCents)}

                            </ResponsiveCell>
                            <ResponsiveCell>

                                <QuantityPicker
                                    min={0}
                                    max={300}
                                    initialValue={product.quantity}
                                    change={(val) => {
                                        setQuantity(product.stripeId, val);
                                    }}
                                />
                            </ResponsiveCell>

                            <ResponsiveCell desktopAlign={'right'}>
                                <Header as ={'h4'}>
                                    {formatCents( product.quantity * product.price.priceInCents)}
                                </Header>
                            </ResponsiveCell>
                        </Table.Row>
                        ))}
                        <Table.Row key={'shipping_row'}>
                            <Table.Cell>
                                

                            </Table.Cell>
                            <Table.Cell />
                                    <Table.Cell>
                                        <Header as={'h5'}>
                                            Shipping
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell textAlign={'right'} >
                                        <Header>
                                            {total < FREE_SHIP ? 
                                                formatCents(shipping)
                                                : 'FREE'
}
                                        </Header>

                                    </Table.Cell>
                        </Table.Row>
                     
                        <Table.Row key={'checkout_row'}>
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell>

                                <Header as={'h4'}>
                                    Total
                                </Header>
                            </Table.Cell>
                            <Table.Cell textAlign={'right'} >
                                <Header textAlign={'right'}>
                                        {formatCents(total < FREE_SHIP ? total + shipping : total)}
                                </Header>

                            </Table.Cell>
                        </Table.Row>
                       
                        {/* <Table.Row key={'contact_row'}>
                            <td>
                                
                                <p style={{ color: '#bfbfbf', fontSize: 14, textAlign: 'left' }}>DETAILS</p>
                                <Input
                                    placeholder={'Email'}
                                />

                            </td>
                            <td colSpan={2}>
                                
                            </td>
                            <td colSpan={1}>
                                <Button onClick={() => setShowPay(true)} disabled={true}>
                                    NEXT
                                </Button>
                            </td>
                        </Table.Row> */}
                        
                        <Table.Row key={'checkout_button_row'}>
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell />
                            <td colSpan={2} style={{
                                height: 130,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }}>
                                {/* <Input
                                    placeholder={'Email'}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                /> */}
                                <Checkout disabled={false} />
                            </td>
                        </Table.Row>
                       
                     
                    </Table>
                    <div style={{ width: '100%'}}>
                        <Button style={{ float: 'right' }} onClick={clearCart}>Clear Cart</Button>
                        </div>
            </div>
    ) : 
        <div
        style={{
            width: '90%',
            maxWidth: 700,
            marginTop: 50,
            marginLeft: 'auto',
            marginRight: 'auto',
        }}
        >
            <Header textAlign={'center'} as={'h1'}>
                Cart is empty
            </Header> 

            <ResponseiveDiv>
                <Link to={'/products'}>
                    <Button size={'huge'} color={'black'}>VIEW RANGE</Button>
                </Link>
            </ResponseiveDiv>
        </div>
}

export default CheckoutPage;


const ResponsiveCell: React.FC<{desktopAlign?: 'center' | 'left' | 'right' }> = ({ children, desktopAlign }) => <Table.Cell textAlign={isMobile ? 'center' : desktopAlign ?? 'left'}>{children}</Table.Cell>