interface ProductConfig {
    identifier: string;
    colour: string;
    productId: string;
    priceId: string;
    price: number;
}
export interface AppConfig {
    stripeKey: string;
    slackHook: string;
    checkoutUrl: string;
    products: ProductConfig[];
}

export let Config: AppConfig;

if ((window as any).frosteConfig) {
    const windowConfig: any = (window as any).frosteConfig;
    Config = windowConfig;
} else {
    Config = ({
        slackHook: '',
        stripeKey: '',
        checkoutUrl: '',
        products: []
    });
}

