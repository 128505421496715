import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { CartContext } from './CartContext';
import ResponseiveDiv from './components/ResponsiveDiv';
import { ResponsiveContainer } from './Layout';

export function Success() {
    const [, , , , clearCart] = React.useContext(CartContext)

    React.useEffect(() => {
        clearCart();
    }, [clearCart]);
    return (
        <ResponseiveDiv style={{
            textAlign: 'center',
            width: '100%',
            padding: 60,
        }}>
            <Header as='h3' textAlign={'center'} style={{ width: '100%'}}>
            
            Order successfully completed!
            </Header>
        </ResponseiveDiv>
    )
}