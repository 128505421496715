/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import { setMaxListeners } from 'process';
import React from 'react'
import {
  Breadcrumb,
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import Stock from './assets/images/stock2.jpeg';
import Logo from './assets/images/logoWhite.png';
import WhiteCan from './assets/images/whiteSkinny.jpg';
import BlackCan from './assets/images/blackFrost.jpg';
import BlueCan from './assets/images/blueFrost.jpg';
import { Link } from 'react-router-dom';
import {
    isMobile
  } from "react-device-detect";
  import { HomepageProductColumn } from './components/HomepageProductColumn';
  
import LogoBlack from './assets/images/logoBlack.png';

const Shipping = () => {

    React.useEffect(() => {
        console.log('IsMobile', isMobile);
    }, [isMobile]);

    return (
  <>

    <Segment style={{ padding: '2em 0em' }} vertical>
      <Breadcrumb>
          <Link to={'/'}>
              <Breadcrumb.Section link>Home</Breadcrumb.Section>
          </Link>
          <Breadcrumb.Divider  icon='right angle' />
          <Breadcrumb.Section active>Shipping</Breadcrumb.Section>
      </Breadcrumb>
      <Grid container stackable columns={3} verticalAlign='middle'>
          <Grid.Row centered>
          <Header as='h3' style={{ fontSize: '2em' }}>
              SHIPPING
            </Header>
          </Grid.Row>
          <Grid.Row><p style={{ textAlign: 'center'}}>
          We will do our best to get your new FROSTE to you as quickly as possible.
<br/>
<br/>
Once an order has been placed, it is sent straight to the warehouse for picking and postage. Unfortunately any placed orders cannot be amended.
<br/>
All orders will be sent with tracking. 
<br/>
All orders sent to residential or commercial premises will require a signature on delivery. If you want your order to be left in a safe place in your absence, please put this in the notes upon checkout.
<br/>
<br/>
Free shipping for all orders over $50 Australia wide.
<br/>
All orders under $50 will incur a shipping fee of $8.95.
<br/>
All orders will be dispatched within 48 business hours. We estimate shipping will take approximately 5-7 business days.
<br/>
Orders may be shipped over several packages.
<br/>
We apologise for any unforeseen delays. 
</p>  </Grid.Row>
      </Grid>
    </Segment>
    
  </>
);
}

export default Shipping