import { Grid, Header, Image } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import React from 'react';
import { gallery } from '../Gallery';

export const HomepageProductColumn: React.FC<{ images: string[] | string, header: string, description?: string, productId: string, colourId: string }> = (
    {
        images,
        header,
        productId,
        children,
        colourId,
    }
) => {
    const sliderRef = React.useRef<ImageGallery>(null);

    const h = useHistory();

    const navToProduct = () => {
        h.push(`/product/${productId}/${colourId}`);
    }

    return (
        <Grid.Column style={{
            cursor: 'pointer',
        }}>
                {!Array.isArray(images)
                    ? <Image rounded size='large' src={images} />
                    :  
                    <ImageGallery
                        items={gallery(images)}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={h.location.pathname.includes('products')}
                        showThumbnails={false}
                        lazyLoad
                    additionalClass={'slider_image-padding'}
                        ref={sliderRef}
                        onClick={navToProduct}
                        onMouseOver={() => {
                            if (sliderRef.current) {
                                const current = sliderRef.current.getCurrentIndex();
                                if (current === 0 && images.length > 1) {
                                    sliderRef.current.slideToIndex(1);
                                }
                            }
                        }}
                        onMouseLeave={() => {
                            if (sliderRef.current) {
                                sliderRef.current.slideToIndex(0);
                            }
                        }}
                    />
                }
            <Link to={`/product/${productId}/${colourId}`} style={{ cursor: 'pointer' }}>
                <Header as='h3' textAlign={'center'} className={'landing__product-header'}>
                {header}
                </Header>
            </Link>
            {children}
        </Grid.Column>
    )
}