import { Popup } from 'semantic-ui-react'
import React from 'react';

const ColourPicker: React.FC<{ hash: string, active: boolean, onPress: () => void, name: string }> = ({ hash, active, onPress, name }) => {

    return (
        <Popup
            content={name}
            position={'top center'}
            inverted
            trigger={
                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        backgroundColor: active ? '#E7E7E7' : '#fff',
                        borderWidth: 1,
                        borderColor: '#E7E7E7',
                        borderStyle: 'solid',
                        marginBottom: 8,
                        marginTop: 8,
                        cursor: 'pointer',
                    }}
                    onClick={onPress}
                >
                    <div
                        style={{
                            width: 38,
                            height: 38,
                            backgroundColor: hash,
                            borderRadius: 19,
                        }}
                    />
                </div>
            }

        />
    )
}

export default ColourPicker;