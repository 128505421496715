export  interface Product {
    identifier: string;
    name: string;
    colours: ProductColour[]
    description?: React.ReactNode;
    outOfStock?: boolean;
}

export  interface Price {
    stripeId: string;
    priceInCents: number;
}

export  interface ProductColour {
    stripeId: string;
    name: string;
    hash: string;
    images: string[];
    price: Price;
    id: string;
}

export interface CartItem {
    stripeId: string;
    productName: string;
    colourName: string;
    quantity: number;
    image: string;
    price: Price;

}

export const toCart = (p: ProductColour, name: string, quantity: number = 1): CartItem => ({
    stripeId: p.stripeId,
    colourName: p.name,
    image: p.images[0],
    productName: name,
    price: p.price,
    quantity
})

export interface Attributes {
    colour: string;
}

export interface Inventory {
    quantity: number;
    type: string;
    value?: any;
}



export interface Datum {
    id: string;
    object: string;
    active: boolean;
    attributes: Attributes;
    created: number;
    currency: string;
    image?: any;
    inventory: Inventory;
    livemode: boolean;
    metadata: {
        [key: string]: string;
    };
    package_dimensions?: any;
    price: number;
    product: string;
    updated: number;
}