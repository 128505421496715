import React from 'react';

import ImageGallery from 'react-image-gallery';

import {formatCents} from './format';
import { Breadcrumb, Button, Grid, Header, Image } from 'semantic-ui-react';
import { QuantityPicker } from './components/Quantity';
import ColourSelect from './components/ColourSelect';
import { Link, useParams } from 'react-router-dom';
import { Product, ProductColour, toCart } from './models';
import { CartContext } from './CartContext';
import ResponseiveDiv from './components/ResponsiveDiv';


import Afterpay from './assets/images/afterpay.png'

import { isMobile } from 'react-device-detect';
import { gallery } from './Gallery';
import { ProductsData } from './product-config';

const ProductView = () => {
    const [cart, addToCart] = React.useContext(CartContext)

    const products: Product[] = ProductsData;

    const [product, setProduct] = React.useState<Product>();
    const [selectedColour, setSelectedColour] = React.useState<ProductColour>();
    const [quantity, setQuantity] = React.useState(1);

    const params = useParams();

    React.useEffect(() => {
        if (!product) {
            return;
        }
        if ('colour' in params) {
            const colourId = params['colour'];
            const colour = product.colours.find(col => col.id === colourId);
            setSelectedColour(colour ?? product.colours[0]);
        } else {

            setSelectedColour(product.colours[0])
        }
    }, [params, product])

    const textAlgn = () => isMobile ? 'center' : 'left';

    let { id } = useParams<{id: string}>();

    React.useEffect(() => {
        // if (products && products.length > 0) setProduct(products[0]);
        const paramProduct = products.find(p => p.identifier === id);
        if (!paramProduct) {
            setProduct(products[0]);
        }
        else {
            setProduct(paramProduct)
        }
    }, [id, products]);


    return product && selectedColour ? (
        <div style={{width: '100%', height: '100%', paddingTop: 20}}>
            <div style={{
                marginLeft: 40,
                marginBottom: isMobile ? 40 : 0
            }}>
                <Breadcrumb>
                    <Link to={'/'}>
                        <Breadcrumb.Section link>Home</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider  icon='right angle' />
                    <Link to={'/products'}>
                        <Breadcrumb.Section link>Products</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active>Product</Breadcrumb.Section>
                </Breadcrumb>
            </div>

      <Grid container stackable columns={2} verticalAlign='middle' centered={isMobile}>
          <Grid.Row>
              <Grid.Column>
                    {/* <Image src={selectedColour.images[0]} /> */}
                    <ImageGallery
                        items={gallery(selectedColour.images)}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={false}
                        thumbnailPosition={'left'}
                    />
              </Grid.Column>
              <Grid.Column>
                    <Header textAlign={textAlgn()}>{product.name}</Header>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            textAlign: textAlgn(),
                            paddingRight: 5,
                        }}>{formatCents(selectedColour.price.priceInCents)}</div>
                        <Image src={Afterpay} width={120} />
                    </div>
                    <Header as={'h5'} textAlign={textAlgn()}>
                        Colour: 
                    </Header>
                    <ResponseiveDiv>
                        {product.colours.map(colour => (
                        <ColourSelect
                            name={colour.name}
                            hash={colour.hash}
                            active={selectedColour.stripeId === colour.stripeId}
                            onPress={() => {
                                setSelectedColour(colour);
                            }}
                        />
                        ))}
                    </ResponseiveDiv>
                    <Header as={'h5'} textAlign={textAlgn()}>
                        Quantity:<br/>
                    </Header>
                    <ResponseiveDiv>

                        <QuantityPicker
                            min={1}
                            max={300}
                            change={(val) => setQuantity(val)}
                        />
                    </ResponseiveDiv>
                    <br />
                    <br />
                    <ResponseiveDiv>
                        <Button
                            size={'huge'}
                            color={'black'}
                            disabled={product.outOfStock}
                            onClick={() => {
                                addToCart(toCart(selectedColour, product.name, quantity));
                            }}
                        >
                            {product.outOfStock ? 'Out of Stock' : 'Add To Cart'} 
                        </Button>
                    </ResponseiveDiv>
                    <ResponseiveDiv style={{
                        marginTop: 10
                    }}>

                        {cart.length > 0 ?
                        <Link to={'/checkout'}>
                            <Button
                                size={'huge'}
                                color={'black'}
                            >
                                Checkout
                            </Button>
                        </Link> : null}
                    </ResponseiveDiv>
                    <ResponseiveDiv style={{
                        marginTop: 20
                    }}>
                        {product.description}
                    </ResponseiveDiv>
              </Grid.Column>
            
            </Grid.Row>
        </Grid>
        </div>
    ) : <div>Loading...</div>
}
export default ProductView;
