import React from 'react';

import { Breadcrumb, Button, Grid, Header, Icon } from 'semantic-ui-react';

import { QuantityPicker } from './components/Quantity';

import { Link } from 'react-router-dom';

import { Product, ProductColour, toCart } from './models';

import { CartContext } from './CartContext';

import { HomepageProductColumn } from './components/HomepageProductColumn';

import { ProductsData as products } from './product-config';

import { isMobile } from 'react-device-detect';

import styled from 'styled-components';
import { formatCents } from './format';


const ProductsView = () => {
    const [cart, addToCart] = React.useContext(CartContext)

    return products ? (
        <div style={{width: '85%', height: '100%', paddingTop: 20, margin: '0 auto'}}>
            <div style={{
                marginLeft: 40,
                marginBottom: isMobile ? 40 : 0
            }}>
                <Breadcrumb>
                    <Link to={'/'}>
                        <Breadcrumb.Section link>Home</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider  icon='right angle' />
                    <Breadcrumb.Section active>Products</Breadcrumb.Section>
                </Breadcrumb>
            </div>
      <Grid container stackable columns={2} verticalAlign='middle'>
          <Grid.Row centered>
          <Header as='h3' style={{ fontSize: '2em' }}>
              THE COLLECTION
            </Header>
          </Grid.Row>
        <Grid.Row verticalAlign={'top'} textAlign={'center'}>
            {products.map(prod => 
                prod.colours.map(colour => <ProductColumn prod={prod} colour={colour} key={`prodcol_${colour.stripeId}`} />
            ))}

          
        </Grid.Row>
      </Grid>
      {cart.length > 0 && 
        <FloatingDiv>
            {/* <Button size={'huge'} color={'black'}>Checkout Now</Button> */}
            <Link to={'/checkout'} >
            <Button animated='vertical' size={'large'} color={'green'}>
                <Button.Content visible>Go to Cart</Button.Content>
                <Button.Content hidden>
                    {/* <Icon name='shop' /> */}
                    <Icon name='arrow right' />

                </Button.Content>
            </Button>
            </Link>
        </FloatingDiv>}
        </div>
    ) : <div>Loading...</div>
}
export default ProductsView;



const ProductColumn: React.FC<{ colour: ProductColour, prod: Product}> = ({ prod, colour}) => {
    const [quantity, setQuantity] = React.useState(1);

    const [cart, addToCart] = React.useContext(CartContext)

    return (
        <HomepageProductColumn
                images={colour.images}
                header={colour.name}
                productId={prod.identifier}
                description={prod.name}
                key={`home_${colour.stripeId}`}
                colourId={colour.id}
            >
                <div style={{
                    height: 130,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                }}>
                    <p style={{ fontSize: '14px', textAlign: 'center' }}>{formatCents(colour.price.priceInCents)}</p>

                    <QuantityPicker
                        min={1}
                        max={300}
                        change={(val) => setQuantity(val)}
                    />
                    <Button
                        size={'huge'}
                        color={'black'}
                        disabled={prod.outOfStock}
                        onClick={() => {
                            addToCart(toCart(colour, prod.name, quantity));

                        }}
                    >
                        {prod.outOfStock ? 'Out of Stock' : 'Add To Cart'}
                    </Button>
                </div>
        </HomepageProductColumn>
    )
}

const FloatingDiv = styled.div`
position:fixed;
bottom: ${isMobile ? '10px' : '150px]'};
right: 10px;
z-index: 999;
`;
