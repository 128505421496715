/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import { setMaxListeners } from 'process';
import React from 'react'
import {
  Button,
  ButtonProps,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import { CartContext } from './CartContext';
import Footer from './components/Footer';

import Beach from './assets/images/42216502-happy-friends-doing-barbecue-and-drinking-beer-at-the-beach.jpg';
import Stock from './assets/images/stock2.jpeg';
import Logo from './assets/images/logoBlack.png';
import LogoWhite from './assets/images/logoWhite.png';
import { Link } from 'react-router-dom';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})


const MenuButton = (props: ButtonProps) => <Button as='a'  style={{ marginLeft: '0.5em', border: 'none', color: '#fff', backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none !important' }}  inverted={false} primary={false}>
{props.children}
</Button>

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
const DesktopContainer:React.FC = ({ children }) => {
    const [fixed, setFixed] = React.useState(false);

    const hideFixedMenu = () => setFixed(false);
    const showFixedMenu = () => setFixed(true);
    const [cart, setCart] = React.useContext(CartContext)

    return (
      <Media greaterThan='mobile'>
        <Visibility
          once={false}
          onTopPassed={showFixedMenu}
          onTopPassedReverse={hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
        
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : undefined}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
              borderles
              style={{
                backgroundColor: '#1B1C1D',
              }}
            >
              <Container>
                  <Link to={'/'}>
                    <Menu.Item as='a' position={'left'}>
                        <Image src={LogoWhite} size={'tiny'} />
                    </Menu.Item>
                </Link>

                <Menu.Item position='right'>
                <Link to={'/'}>
                    <MenuButton>
                      HOME
                    </MenuButton>
                  </Link>
                  <Link to={'/products'}>
                    <MenuButton>
                      SHOP
                    </MenuButton>
                  </Link>
                  <Link to={'/checkout'}>
                      <Button as='a' inverted={true} primary={false} style={{ marginLeft: '0.5em' }} icon>
                          <Icon name='cart' />
                          {cart.length > 0  && <span style={{padding: 4}}>{cart.reduce((a, b) => a +b.quantity, 0)}</span>}
                      </Button>
                  </Link>
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>
        <div
            style={{
                minHeight: 800,
            }}
        >
            {children}
        </div>
        <Footer />

      </Media>
    )
}


const MobileContainer: React.FC = ({ children }) => {
    const [sidebarOpened, setSidebarOpened] = React.useState(false);
    const handleSidebarHide = () => setSidebarOpened(false);
    const handleToggle = () => setSidebarOpened(true);

    const [fixed, setFixed] = React.useState(false);

    const hideFixedMenu = () => setFixed(false);
    const showFixedMenu = () => setFixed(true);
    const [cart, setCart] = React.useContext(CartContext)

    return (
      <Media at='mobile'>
        <Visibility
          once={false}
          onTopPassed={showFixedMenu}
          onTopPassedReverse={hideFixedMenu}
        >
            <Segment
              inverted
              textAlign='center'
            //   style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                {/* <Menu inverted pointing secondary size='large'> */}
                <Menu
                    fixed={fixed ? 'top' : undefined}
                    inverted={!fixed}
                    pointing={!fixed}
                    secondary={!fixed}
                    size='large'
                    borderless
                >
        
                  <Link to={'/'}>
                    <Menu.Item as='a'>
                        <Image src={fixed ? Logo : LogoWhite} size={'tiny'} />

                    </Menu.Item>
                  </Link>
                {/* <Menu.Item position='right'>
                  <Button as='a'  style={{ marginLeft: '0.5em' }} icon  inverted={true} primary={false}>
                    Shop
                  </Button>
                </Menu.Item> */}
                <Menu.Item position='right'>
                    <Link to={'/checkout'}>
                        <Button as='a' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }} icon>
                            <Icon name='cart' />
                            {cart.length > 0  && <span style={{padding: 4}}>{cart.reduce((a, b) => a +b.quantity, 0)}</span>}
                        </Button>
                    </Link>
                </Menu.Item>
                </Menu>
              </Container>
            </Segment>
        </Visibility>
            {children}
          {/* </Sidebar.Pusher>
        </Sidebar.Pushable> */}
        <Footer />
      </Media>
    )
}

export const ResponsiveContainer: React.FC = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)
