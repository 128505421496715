import React, { createContext, useReducer } from 'react';
import { CartItem } from './models';
import { reject } from 'ramda';
import { FirebaseContext } from './Firebase';

export const CartContext = createContext<[
    cart: CartItem[], 
    addProduct: (c: CartItem) => void,
    removeProduct: (c: string) => void,
    setQuantity: (id: string, quantity: number) => void,
    clearCart: () => void,
    getItemsForCheckout: () => StripeCartItem[],
]>([
    [], 
    () => {}, 
    () => {},
    () => {},
    () => {},
    () => [],
])

const storage = localStorage.getItem('froste_cart') ? JSON.parse(localStorage.getItem('froste_cart') ?? '') : [];

export const FREE_SHIP = 5000;

type StripeCartItem = {
    id: string;
    quantity: number;
    price: string;
}

const CartContextProvider: React.FC = ({ children }) => {
    const [cart, setCart] = React.useState<CartItem[]>([]);
    
    const { logEvent } = React.useContext(FirebaseContext)

    const addProduct = (item: CartItem) => {
        let found = false;
        logEvent('productAddedToCart');

        cart.map(cartItem => {
            if (cartItem.stripeId === item.stripeId) {
                cartItem.quantity += item.quantity;
                found = true;
            }
        });
        if (!found) {
            cart.push(item);
        }
        setCart([...cart]);
    }

    const clearCart = () => {
        setCart([]);
    }

    const removeProduct = (id: string) => {
        // cart.map(cartItem => {
        //     if (cartItem.stripeId === item.stripeId) {
        //         cartItem.quantity = cartItem.quantity - 1;
        //     }
        // });
        // setCart([...cart]);
        setCart(reject(i => i.stripeId === id, cart))

    }

    const setQuantity = (id: string, quantity: number) => {
        if (quantity === 0) {
            removeProduct(id);
            return;
        }
        cart.map(cartItem => {
            if (cartItem.stripeId === id) {
                cartItem.quantity = quantity
            }
        });
        setCart([...cart]);
    }

    const getItemsForCheckout = (): StripeCartItem[] => {
        const total = cart.reduce((total, item) => total + item.quantity * item.price.priceInCents, 0);

        if (total < FREE_SHIP) {
            return cart.map(product => ({
                id: product.stripeId,
                quantity: product.quantity,
                price: product.price.stripeId,
                priceInCents:product.price.priceInCents,
            }));
        }
        return cart.map(product => ({
            id: product.stripeId,
            quantity: product.quantity,
            price: product.price.stripeId,
            priceInCents:product.price.priceInCents,
        }));

    }

    React.useEffect(() => {
        const storage = localStorage.getItem('froste_cart') ? JSON.parse(localStorage.getItem('froste_cart') ?? '') as CartItem[] : [] as CartItem[];
        if (storage.length > 0) setCart(storage);
    }, []);

    React.useEffect(() => {
        localStorage.setItem('froste_cart', JSON.stringify(cart));
    }, [cart])

    return (
        <CartContext.Provider value={[cart, addProduct, removeProduct, setQuantity, clearCart, getItemsForCheckout]}>
            {children}
        </CartContext.Provider>
    )

}
 
export default CartContextProvider;
