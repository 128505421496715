import React from 'react';
import './Quantity.css';

export const QuantityPicker: React.FC<{min: number, max: number, change: (val: number) => void, initialValue?: number}> = props => {

    const [disableDec, setDisableDec] = React.useState(false);
    const [disableInc, setDisableInc] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [value, setValue] = React.useState(props.initialValue || 1);


    React.useEffect(() => {
      props.change(value);
    }, [value]);

    function increment() {
        const plusState = value + 1;
        if (value < props.max){
            setValue(plusState);
            setDisable(false);
        }
        if (value === (props.max - 1)) {
            setDisableInc(true);
        }
        if (value === props.min) {
            setDisableDec(false);
        }
    }

    function decrement() {
        const minusState = value - 1;
        if (value > props.min) {
            setValue(minusState);
      if (value == props.min + 1) {
            setDisableDec( true);
      }
    } else {
      setValue( props.min);
    }
    if (value == props.max) {
        setDisableInc(false);
    }
  }


    return (
      <div className="quantity-picker">
        <button className={`${disableDec ? 'mod-disable ' : ''}quantity-modifier modifier-left`} onClick={decrement}>&ndash;</button>
        <input className="quantity-display" type="text" value={value} readOnly />
        <button className={`${disableInc ? 'mod-disable ' : ''}quantity-modifier modifier-right`} onClick={increment}>&#xff0b;</button>
      </div>
    );
}