import React from 'react';
import { Config } from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import { CartContext } from '../../CartContext';
import Button from '../../components/Button';
import { FirebaseContext } from '../../Firebase';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.stripeKey);

const Checkout: React.FC<{ disabled: boolean }> = props => {
  const [checkingOut, setCheckingOut] = React.useState(false);
  const [, , , , , getCartItems] = React.useContext(CartContext)
  const { logEvent } = React.useContext(FirebaseContext);

  const handleClick = async () => {
    console.log(getCartItems());

    logEvent('checkoutInitiated');

    setCheckingOut(true)
    // Get Stripe.js instance
    const stripe = await stripePromise;

    if (!stripe) {
      setCheckingOut(false);
      return;
    }

    // Call your backend to create the Checkout Session
    const response = await fetch(Config.checkoutUrl, { 
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        products: getCartItems(),
        base: window.location.origin,
      })
    });

    const session = await response.json();

    console.log(session);

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.data,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    setCheckingOut(false);

  };

  return (
    <Button role="link" onClick={handleClick} disabled={checkingOut || props.disabled}>
      Checkout
    </Button>
  );
}

export default Checkout;