/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import { setMaxListeners } from 'process';
import React from 'react'
import {
  Breadcrumb,
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import Stock from './assets/images/stock2.jpeg';
import Logo from './assets/images/logoWhite.png';
import WhiteCan from './assets/images/whiteSkinny.jpg';
import BlackCan from './assets/images/blackFrost.jpg';
import BlueCan from './assets/images/blueFrost.jpg';
import { Link } from 'react-router-dom';
import {
    isMobile
  } from "react-device-detect";
  import { HomepageProductColumn } from './components/HomepageProductColumn';
  
import LogoBlack from './assets/images/logoBlack.png';

const Shipping = () => {

    React.useEffect(() => {
        console.log('IsMobile', isMobile);
    }, [isMobile]);

    return (
  <>

    <Segment style={{ padding: '2em 0em' }} vertical>
      <Breadcrumb>
          <Link to={'/'}>
              <Breadcrumb.Section link>Home</Breadcrumb.Section>
          </Link>
          <Breadcrumb.Divider  icon='right angle' />
          <Breadcrumb.Section active>Returns</Breadcrumb.Section>
      </Breadcrumb>
      <Grid container stackable columns={3} verticalAlign='middle'>
          <Grid.Row centered>
          <Header as='h3' style={{ fontSize: '2em' }}>
              RETURNS
            </Header>
          </Grid.Row>
          <Grid.Row>
            <p style={{textAlign: 'center'}}>
            If for some reason you are not satisfied with your order or change your mind we will accept and refund the cost of your FROSTE within 30 days from date of purchase in the original condition (unopened, original packaging). This excludes the cost of the postage to us. We cannot accept returns if they have been opened. To return your order please send us and email at info@froste.com.au with your purchase details and return information.
            </p>
          </Grid.Row>
      </Grid>
    </Segment>
    
  </>
);
}

export default Shipping