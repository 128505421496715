import React from "react";
import Product from "./Product";
import Products from "./Products";
import Checkout from "./CheckoutPage";
import Shipping from "./Shipping";
import Returns from "./Returns";
import Landing from "./Landing";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  useHistory,
} from "react-router-dom";
import { ResponsiveContainer } from "./Layout";
import CartContextProvider from "./CartContext";
import "firebase/analytics";

// import './Stripe.css';
import "./App.css";
// import 'semantic-ui-css/semantic.min.css'
import "semantic-ui-less/semantic.less";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Success } from "./Success";
import { FirebaseContext, FirebaseProvider } from "./Firebase";
import { Config } from "./config";

function WithProviders() {
  return (
    <FirebaseProvider>
      <CartContextProvider>
        <App />
      </CartContextProvider>
    </FirebaseProvider>
  );
}

const filteredIps = [
	'180.150.38.72',
	'1.136.23.245',
  '1.159.97.98',
]

function App() {

	const [infoSent, setInfoSent] = React.useState(localStorage.getItem('froste_visit'));

	const visitHistory = React.useRef((localStorage.getItem('froste_visit_history') ?? '').split(',').filter(entry => entry !== ''));

	const sendingData = (date: Date) => {

		const dateString = date.toISOString();
		localStorage.setItem('froste_visit', dateString);
		localStorage.setItem('froste_visit_history', [...visitHistory.current ? visitHistory.current : [], dateString].filter(entry => entry !== '').join(','))
		setInfoSent(dateString);
	}


	const getData = async () => {
		const res = await fetch('https://geolocation-db.com/json/')
		const userdata = await res.json();
		if (filteredIps.includes(userdata.IPv4)) {
			return;
		}
		// await fetch(Config.slackHook, {
		// 	method: 'POST',
		// 	mode: 'cors', // no-cors, *cors, same-origin
		// 	credentials: 'same-origin', // include, *same-origin, omit
		// 	body: JSON.stringify({
		// 		text: `New Visitor from ${userdata.city}`, // this is notification ttext
		// 		blocks: [
		// 		  {
		// 			type: "header",
		// 			text: {
		// 			  type: "plain_text",
		// 			  text: `New Visitor ${userdata.IPv4}`,
		// 			}
		// 		  },
		// 		  {
		// 			type: "section",
		// 			text: {
		// 			  type: "plain_text",
		// 			  text: `from ${userdata.city ? `${userdata.city}, ` : ''}${userdata.state ? `${userdata.state}, ` : ''}${userdata.country_name}`,
		// 			}
		// 		  },
		// 		  ...visitHistory.current && visitHistory.current.length > 0 ?
		// 		  [{
		// 			type: "section",
		// 			text: {
		// 			  type: "plain_text",
		// 			  text: `${visitHistory.current.length} previous visits`,
		// 			}
		// 		  }] : [],
		// 		  {
		// 			type: "divider"
		// 		  }
		// 		]
		// 	  })
		// })
	  }
	  
	React.useEffect( () => {
		var d1 = new Date (),
			d2 = new Date ( d1 );
				d2.setMinutes ( d1.getMinutes() - 30 );
		if (infoSent === null || new Date(infoSent) < d2) {
			setInfoSent(d1.toISOString());
			sendingData(d1);
			getData()
		}
	
	}, [infoSent]);

  return (
    <Router>
      <Switch>
        <FrosteRoute
          pageTitle={"FROSTE Insulated Stubby Holders | Melbourne"}
          path="/product/:id/:colour"
        >
          <ResponsiveContainer>
            <Product />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"Products | FROSTE Insulated Stubby Holders | Melbourne"}
          path="/products"
        >
          <ResponsiveContainer>
            <Products />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"Checkout | FROSTE Insulated Stubby Holders | Melbourne"}
          path="/checkout"
        >
          <ResponsiveContainer>
            <Checkout />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"Shipping | FROSTE Insulated Stubby Holders | Melbourne"}
          path="/shipping"
        >
          <ResponsiveContainer>
            <Shipping />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"Returns | FROSTE Insulated Stubby Holders | Melbourne"}
          path="/returns"
        >
          <ResponsiveContainer>
            <Returns />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"FROSTE Insulated Stubby Holders | Melbourne"}
          path="/success"
        >
          <ResponsiveContainer>
            <Success />
          </ResponsiveContainer>
        </FrosteRoute>

        <FrosteRoute
          pageTitle={"FROSTE Insulated Stubby Holders | Melbourne"}
          path="/"
        >
          <ResponsiveContainer>
            <Landing />
          </ResponsiveContainer>
        </FrosteRoute>
      </Switch>
    </Router>
  );
}

type FrosteRouteProps = RouteProps & {
  pageTitle: string;
};
function FrosteRoute(props: FrosteRouteProps) {
  const { pageTitle, ...rest } = props;
  const { logEvent } = React.useContext(FirebaseContext);

  const history = useHistory();

  React.useEffect(() => {
    if (history.location === rest.location) {
      document.title = pageTitle;
      logEvent("screen_view", {
        firebase_screen: pageTitle,
      });
    }
  }, [rest.location, pageTitle, history.location, logEvent]);

  return <Route {...rest}>{props.children}</Route>;
}

export default WithProviders;
