// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp,  } from "firebase/app";
import { logEvent as logEventGA, getAnalytics } from "firebase/analytics";
import React, { ReactFragment } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDktLnOZkJjMM9ex0TB28xDG67v_JuL4h8",
  authDomain: "frost-fac4a.firebaseapp.com",
  databaseURL: "https://frost-fac4a-default-rtdb.firebaseio.com",
  projectId: "frost-fac4a",
  storageBucket: "frost-fac4a.appspot.com",
  messagingSenderId: "1015419750531",
  appId: "1:1015419750531:web:f00198c4d0f240aed2f3b6",
  measurementId: "G-NZCP4LP0YE"
};


export const FirebaseContext = React.createContext<{ 
    app: FirebaseApp | undefined,
    logEvent: (event: string, eventParams?: {
        [key: string]: any;
    }) => void;
    
}>({
    app: undefined,
    logEvent: () => {},
});


export function FirebaseProvider({ children }: { children: React.ReactNode }) {

    const [firebaseApp, setFirebaseApp] = React.useState<FirebaseApp>();

    const initialiseFirebase = () => {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        setFirebaseApp(app);
    }
    const logEvent = React.useCallback((event: string, eventParams?: {
        [key: string]: any;
    }) => {
        if (firebaseApp) {
            logEventGA(getAnalytics(firebaseApp), event, eventParams)
        }
    }, [firebaseApp]);
    
    React.useEffect(() => {
        if (!firebaseApp) {
            initialiseFirebase();
        }
    }, [])
    
    return (
        <FirebaseContext.Provider value={{
            app: firebaseApp,
            logEvent,
        }}>{children}</FirebaseContext.Provider>
    )
}